import React from "react";
import {Col, Row} from "react-bootstrap";
import JSONData from "../../../content/executiveSearchDetailsData.json"
import * as styles from "./executiveSearchDetails.module.scss"
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import {ContactUsButton, LinkButton} from "../linkButton/linkButton";

export default function ExecutiveSearchDetails() {

    const data = useStaticQuery(graphql`{
    imageDesktop: file(relativePath: {eq: "executiveSearch/details.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 70, layout: CONSTRAINED)
      }
    }
  }
  `);

    return <Row className={styles.detailsWrapper}>
        {/*<Row>
            <Col lg={{offset: 1, span: 4}}>
                <div className={styles.headerBigFirstRow}>
                    {JSONData.firstRowLeft.header}
                </div>
                <div className={styles.textBigFirstRow}>
                    {JSONData.firstRowLeft.text}
                </div>
            </Col>
            <Col lg={{span: 6}}>
                <Row>
                    {JSONData.firstRowRight.map((data: any, idx: number) => {
                        return <Col
                            className={idx % 2 === 0 ? styles.subtitlesWrapperLeft : styles.subtitlesWrapperRight}
                            md={{span: 6}} key={idx}>
                            <div className={styles.headerSmall}>{data.header}</div>
                            <div className={styles.textSmall}>{data.text}</div>
                        </Col>
                    })}
                </Row>
            </Col>
                </Row>

        <Row className={styles.secondRowWrapper}>
            <Col lg={{offset: 1, span: 5}}>
                <div className={styles.headerBig}>
                    {JSONData.secondRowLeft.header}
                </div>
                <div className={styles.textBigFirstRow}>
                    {JSONData.secondRowLeft.text}
                </div>
            </Col>
            <Col lg={{span: 5}}>
                {JSONData.secondRowRight.map((data: any, idx: number) => {
                    return <div key={idx}>
                        <div className={styles.headerSmall}>{data.header}</div>
                        <div className={styles.textSmall}>{data.text}</div>
                    </div>
                })}
            </Col>
        </Row>*/}

        <Row>
            <Col className={styles.imageCol} lg={{offset: 1, span: 5}}>
                <GatsbyImage className={styles.image} alt={"person"} image={data.imageDesktop.childImageSharp.gatsbyImageData}/>
            </Col>
            <Col className={styles.colWrapper} lg={{span: 5}}>
                <div className={styles.headerBig}>{JSONData.thirdRow.header}</div>
                <div style={{marginBottom:"2rem"}} className={styles.textSmall}>{JSONData.thirdRow.textFirst}</div>
                <div className={styles.headerSmall}>{JSONData.thirdRow.smallHeader}</div>
                <div style={{marginBottom:"2rem"}} className={styles.textSmall}>{JSONData.thirdRow.textSecond}</div>
                <ContactUsButton to={JSONData.target}>{JSONData.buttonText}</ContactUsButton>
            </Col>
        </Row>
    </Row>
}
