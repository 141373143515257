import * as styles from "./servicesHero.module.scss";
import {Col, Row} from "react-bootstrap";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import {LinkButton} from "../linkButton/linkButton";


export default function ServicesHero(props: ServicesHeroProps) {

    return <Row className={styles.heroWrapper}>
        <Col xs={{span: 12}} lg={{span: 6}} className={styles.textWrapper}>
            <div className={styles.title}>{props.sectionTitle}</div>
            <div className={styles.header}>{props.header}</div>
            <div className={styles.text}>{props.text}</div>
            <LinkButton to={props.target}>{props.buttonText}</LinkButton>
        </Col>
        <Col xs={{span: 12}} lg={{offset: 1, span: 5}} className={styles.imageWrapper}>
            <GatsbyImage className={styles.image} alt={"person"}
                         image={props.image}/>
        </Col>
    </Row>
}

interface ServicesHeroProps {
    sectionTitle: string,
    header: string,
    text: string,
    buttonText: string,
    image: any,
    target: string;
}
