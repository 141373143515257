import {CarouselItem, Col, Row} from "react-bootstrap";
import * as styles from "./carousel.module.scss";
import React, {ReactElement, useEffect, useState} from "react";

let slideCount: number = 0;
export default function Carousel(props: CarouselComponentProps) {

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [previewRightActiveIndex, setPreviewRightActiveIndex] = useState<number>(activeIndex + 1)
    const [previewLeftActiveIndex, setPreviewLeftActiveIndex] = useState<number>(props.slides.length - 1 !== activeIndex ? props.slides.length - 1 : -1)
    const [hiddenRightIndex, setHiddenRightIndex] = useState<number>(activeIndex + 2)
    const [hiddenLeftIndex, setHiddenLeftIndex] = useState<number>(props.slides.length - 2)
    const [progressBarWidth, setProgressBarWidth] = useState<string>("0");
    const [intervalID, setIntervalID] = useState<any>();
    const [slideLeft, setSlideLeft] = useState<boolean>(false);
    const [slideRight, setSlideRight] = useState<boolean>(false);
    const [animationDuration, setAnimationDuration] = useState<string>("1s")

    let interval: NodeJS.Timer;

    let getHiddenLeftIndex = (idx: number) => {
        if (idx - 2 === -1) return props.slides.length - 1;
        if (idx - 2 === -2) return props.slides.length - 2;
        return idx - 2;
    }


    const changeSlide = (idx: number) => {
        if (idx != activeIndex) {
            setActiveIndex(idx);
            setPreviewRightActiveIndex(idx + 1 === props.slides.length ? 0 : idx + 1);
            setHiddenRightIndex((idx + 2) % props.slides.length);
            setHiddenLeftIndex(getHiddenLeftIndex(idx));
            setPreviewLeftActiveIndex(idx - 1 === -1 ? props.slides.length - 1 : idx - 1);
            setProgressBarWidth("0");
            fillProgressBar(idx);
        }
    }

    useEffect(() => {
        fillProgressBar(0);
    }, [])

    let fillProgressBar = (idx: number) => {
        slideCount === 0 && setAnimationDuration("1s");
        clearInterval(intervalID);
        clearInterval(interval);
        let i = 0;
        if (slideCount === 0) {
            setIntervalID(interval = setInterval(() => {
                setProgressBarWidth(i + "%");
                i++;
                if (i > 99) {
                    i = 100;
                    setSlideLeft(true);
                }
            }, 50));
        }
    }

    return <Row className={styles.previewRow}>

        <div style={{animationDuration: animationDuration}}
             className={slideRight ? `${styles.carouselWrapper} ${styles.slideOutLeft}` : slideLeft ? `${styles.carouselWrapper} ${styles.slideOutRight}` : styles.carouselWrapper}
             onAnimationEnd={() => {
                 if (slideLeft) {
                     changeSlide(activeIndex + 1 === props.slides.length ? 0 : activeIndex + 1);
                     setSlideLeft(false);
                     if (slideCount && slideCount !== 0) {
                         setTimeout(() => setSlideLeft(true), 20);
                         slideCount--;
                     }
                 }
                 if (slideRight) {
                     changeSlide(activeIndex - 1 === -1 ? props.slides.length - 1 : activeIndex - 1);
                     setSlideRight(false);
                     if (slideCount && slideCount !== 0) {
                         setTimeout(() => setSlideRight(true), 20);
                         slideCount--;
                     }
                 }
             }}>
            <Col className={styles.clientCardWrapper} lg={{offset: 1, span: 10}}>
                <div className={styles.carouselItemWrapper}>
                    {props.slides[activeIndex].slide}
                </div>
            </Col>
        </div>

        <div style={{animationDuration: animationDuration}}
             className={slideRight ? `${styles.nextCardPreview} ${styles.slideOutLeft}` : slideLeft ? `${styles.nextCardPreview} ${styles.slideInLeft}` : styles.nextCardPreview}
             onClick={() => {
                 animationDuration !== "1s" && setAnimationDuration("1s");
                 setSlideLeft(true);
             }}>
            <Col className={styles.clientCardWrapper} lg={{offset: 1, span: 10}}>
                <div className={styles.carouselItemWrapper}>
                    {props.slides[previewRightActiveIndex] && props.slides[previewRightActiveIndex].slide}
                </div>
            </Col>
        </div>

        <div style={{animationDuration: animationDuration}}
             className={slideLeft ? `${styles.hiddenCardRight} ${styles.slideInLeftHidden}` : styles.hiddenCardRight}>
            <Col className={styles.clientCardWrapper} lg={{offset: 1, span: 10}}>
                <div className={styles.carouselItemWrapper}>
                    {props.slides[hiddenRightIndex] && props.slides[hiddenRightIndex].slide}
                </div>
            </Col>
        </div>

        <div style={{animationDuration: animationDuration}}
             className={slideRight ? `${styles.previousCardPreview} ${styles.slideInRight}` : slideLeft ? `${styles.previousCardPreview} ${styles.slideOutRight}` : styles.previousCardPreview}
             onClick={() => {
                 animationDuration !== "1s" && setAnimationDuration("1s");
                 setSlideRight(true)
             }}>
            <Col className={styles.clientCardWrapper} lg={{offset: 1, span: 10}}>
                <div className={styles.carouselItemWrapper}>
                    {props.slides[previewLeftActiveIndex] && props.slides[previewLeftActiveIndex].slide}
                </div>
            </Col>
        </div>

        <div style={{animationDuration: animationDuration}}
             className={slideRight ? `${styles.hiddenCardLeft} ${styles.slideInRightHidden}` : styles.hiddenCardLeft}>
            <Col className={styles.clientCardWrapper} lg={{offset: 1, span: 10}}>
                <div className={styles.carouselItemWrapper}>
                    {props.slides[hiddenLeftIndex] && props.slides[hiddenLeftIndex].slide}
                </div>
            </Col>
        </div>

        <div className={styles.carouselOptionsWrapper}>
            {props.slides.map((data: any, idx: number) => {
                return <div key={idx} className={styles.optionWrapper}>
                    <button
                        className={activeIndex === idx ? `${styles.carouselOption} ${styles.carouselChosen}` : styles.carouselOption}
                        onClick={() => {
                            animationDuration !== "1s" && setAnimationDuration("1s")
                            idx > activeIndex ? setSlideLeft(true) : setSlideRight(true);
                            idx > activeIndex ? slideCount = Math.abs(idx - activeIndex - 1) : slideCount = Math.abs(idx - activeIndex + 1);
                            slideCount > 0 && setAnimationDuration("0.3s")
                        }}>{data.label}</button>
                    {activeIndex === idx &&
                        <div className={styles.decor}><span style={{width: progressBarWidth}}/></div>}
                </div>
            })
            }
        </div>

    </Row>

}

export interface CarouselComponentProps {
    slides: Slide[];
}

interface Slide {
    slide: ReactElement,
    label?: string
}
