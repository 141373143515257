import Layout from "../../components/layout/layout";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import React from "react";
import ExecutiveSearchDetails from "../../components/executiveSearchDetails/executiveSearchDetails";
import FaqSection from "../../components/FaqSection/faqSection";
import pageHeroDataJSON from "../../../content/executiveSearchPageHeroData.json";
import ServicesHero from "../../components/servicesHero/servicesHero";
import {graphql, useStaticQuery} from "gatsby";
import MoreAboutUs from "../../components/whoWeAre/moreAboutUsExecutiveSearch";
import Cta from "../../components/cta/cta";
import OurWork from "../../components/ourWork/ourWork";
import JSONData from "../../../content/executiveSearchAdvancedCtaData.json"
import AdvancedCta from "../../components/cta/advancedCta";
import {Helmet} from "react-helmet";

export default function ExecutiveSearch() {

    const data = useStaticQuery(graphql`{
    image1: file(relativePath: {eq: "executiveSearch/pageHero.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 70, layout: CONSTRAINED)
      }
    }
    }`);

    return <>
        <Helmet>
          <meta charSet="utf-8" />
          <html lang="en" />
          <title>Search - Recoded Recruitment</title>
        </Helmet>
        <Layout
        header={<Header transparent={false}/>}
        footer={<Footer/>}
    >
        <ServicesHero sectionTitle={pageHeroDataJSON.sectionTitle} header={pageHeroDataJSON.header}
                      text={pageHeroDataJSON.text} buttonText={pageHeroDataJSON.buttonText}
                      image={data.image1.childImageSharp.gatsbyImageData} target={pageHeroDataJSON.target}/>
        <ExecutiveSearchDetails/>
        {/* <FaqSection/> */}
        <Cta title="Book a call with us to discuss your hiring challenges today" buttonText="Book a call" buttonTarget="/book-a-call" />
        <OurWork/>
        <AdvancedCta header={JSONData.header} text={JSONData.text} firstButtonText={JSONData.firstButtonText}
                     secondButtonText={JSONData.secondButtonText} firstButtonTarget={JSONData.firstButtonTarget}
                     secondButtonTarget={JSONData.secondButtonTarget}/>
        {/* <MoreAboutUs/> */}
    </Layout>
        </>
}
