// extracted by mini-css-extract-plugin
export var colWrapper = "executiveSearchDetails-module--colWrapper--0bc62";
export var detailsWrapper = "executiveSearchDetails-module--detailsWrapper--95e2d";
export var headerBig = "executiveSearchDetails-module--headerBig--37072";
export var headerBigFirstRow = "executiveSearchDetails-module--headerBigFirstRow--15df9";
export var headerSmall = "executiveSearchDetails-module--headerSmall--0dc24";
export var image = "executiveSearchDetails-module--image--67831";
export var imageCol = "executiveSearchDetails-module--imageCol--7197a";
export var secondRowWrapper = "executiveSearchDetails-module--secondRowWrapper--3cf3a";
export var subtitlesWrapperLeft = "executiveSearchDetails-module--subtitlesWrapperLeft--6af3a";
export var subtitlesWrapperRight = "executiveSearchDetails-module--subtitlesWrapperRight--98879";
export var textBigFirstRow = "executiveSearchDetails-module--textBigFirstRow--03c46";
export var textSmall = "executiveSearchDetails-module--textSmall--56746";