// extracted by mini-css-extract-plugin
export var carouselChosen = "carousel-module--carouselChosen--4049a";
export var carouselItemWrapper = "carousel-module--carouselItemWrapper--8e080";
export var carouselOption = "carousel-module--carouselOption--667d8";
export var carouselOptionsWrapper = "carousel-module--carouselOptionsWrapper--85e47";
export var clientCardWrapper = "carousel-module--clientCardWrapper--3f978";
export var decor = "carousel-module--decor--aed9f";
export var hiddenCardLeft = "carousel-module--hiddenCardLeft--4c15b";
export var hiddenCardRight = "carousel-module--hiddenCardRight--a11cc";
export var nextCardPreview = "carousel-module--nextCardPreview--65ed7";
export var optionWrapper = "carousel-module--optionWrapper--d5508";
export var previewRow = "carousel-module--previewRow--91c08";
export var previousCardPreview = "carousel-module--previousCardPreview--a9b19";
export var slideInLeft = "carousel-module--slideInLeft--c33b5";
export var slideInLeftHidden = "carousel-module--slideInLeftHidden--d7abc";
export var slideInLeftHiddenKeyframes = "carousel-module--slide-in-left-hidden-keyframes--48ec5";
export var slideInLeftKeyframes = "carousel-module--slide-in-left-keyframes--9ceed";
export var slideInRight = "carousel-module--slideInRight--113f4";
export var slideInRightHidden = "carousel-module--slideInRightHidden--e08be";
export var slideInRightHiddenKeyframes = "carousel-module--slide-in-right-hidden-keyframes--e469d";
export var slideInRightKeyframes = "carousel-module--slide-in-right-keyframes--7036f";
export var slideOutLeft = "carousel-module--slideOutLeft--26920";
export var slideOutLeftKeyframes = "carousel-module--slide-out-left-keyframes--50857";
export var slideOutLeftMobileKeyframes = "carousel-module--slide-out-left-mobile-keyframes--c19d6";
export var slideOutRight = "carousel-module--slideOutRight--26761";
export var slideOutRightKeyframes = "carousel-module--slide-out-right-keyframes--2d8ab";
export var slideOutRightMobileKeyframes = "carousel-module--slide-out-right-mobile-keyframes--4c48f";