import React from "react"
import * as styles from "./ourWork.module.scss"
import JSONdata from "../../../content/ourWorkData.json"
import ClientCard from "../clientCard/clientCard";
import GithubLogoSVG from "../../svg/githubLogo.svg";
import ForgerockSVG from "../../svg/clients/forgerockSVG.svg";
import MedableSVG from "../../svg/clients/medableSVG.svg";
import NearSVG from "../../svg/clients/nearFoundation.svg";
import Carousel from "../carousel/carousel";

export default function OurWork() {
    let slides:any = JSONdata.clientTilesList.slice(0,4).map((data: any, idx: number) => {
        let logo = <GithubLogoSVG />;
        switch (data.slug) {
            case "github-case-study":
                logo = <GithubLogoSVG />
                break;
            case "forgerock-case-study":
                logo = <ForgerockSVG />
                break;
            case "medable-case-study":
                logo = <MedableSVG />
                break;
            case "near-foundation-case-study":
                logo = <NearSVG />
                break;
            default:
                break;
        }
        return {
            slide: <ClientCard logo={logo} name={data.name} header={data.header} text={data.text}
                               statistics={data.statistics} background={data.color} key={idx} slug={data.slug} />,
            label:data.name
        }
    });

    return <div className={styles.wrapper}>
        <div className={styles.header}>
            Our work
        </div>
        <div className={styles.title}>
            {JSONdata.title}
        </div>
        <Carousel slides={slides}/>
    </div>
}
