import React from "react";
import {LinkButton, LinkButtonOutline} from "../linkButton/linkButton";
import * as styles from "./advancedCta.module.scss"

export default function AdvancedCta(props: AdvancedCta) {
    return <div className={styles.ctaWrapper}>
        <div className={styles.header}>{props.header}</div>
        <div className={styles.text}>{props.text}</div>
        <div className={styles.buttonsWrapper}>
            <LinkButton to={props.firstButtonTarget}>{props.firstButtonText}</LinkButton>
            <LinkButtonOutline to={props.secondButtonTarget}>{props.secondButtonText}</LinkButtonOutline>
        </div>
    </div>
}

interface AdvancedCta {
    header: string,
    text: string,
    firstButtonText: string,
    secondButtonText: string,
    firstButtonTarget: string,
    secondButtonTarget: string,
}
